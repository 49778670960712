@import './_variables';
body {
  font-family: $font;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  width: 100%;
  color: $black;
}
* {
  outline: none;
  box-sizing: border-box;
}
.container {
  margin: 60px auto 0;
  padding: 30px 0;
  min-height: calc(100vh - 100px);
  position: relative;
}
